import React from 'react'

import Cookiesky from './cookies/Cookiesky'

export default function Output(props) {
    // premenna orezanieNazvu sa prepisuje podla sirky obrazovky
    // var orezanieNazvu = window.innerWidth > 766? 60: (window.innerWidth >= 400? 30: (window.innerWidth >= 330? 25: 20));
    var orezanieNazvu = 10000; // nekonecno 
    
    //premenna mobilnaVerzia zaznamenava ci o ktoru verziu sa jedna...dalej s nou mozeme pracovat v HTMLku
    var mobilnaVerzia = window.innerWidth <= 766? true: false;

    return (
       <div className="fs-search-vysledky">
           <ul style={{listStyleType: 'none'}} className="list-group">
                {props.cookiesTickery.map((value, index) => { 
                    /*                                <div style={{marginLeft: 'auto'}} className="fs-search-vysledky-sekcia-marketu">
                                    { value.idSkupiny != null ? 
                                        <span className="fs-search-vysledky-skupina" key={index+'s'} style={{backgroundColor: value.farbaSkupiny, color: value.farbaPismaSkupiny}}
                                        ref={(el) => {if (el) {
                                            el.style.setProperty('background-color', value.farbaSkupiny, 'important');
                                        }}}>{value.idSkupiny}</span> 
                                        : null }
                                    { value.idTypu != null ? 
                                        <span className="fs-search-vysledky-typ" key={index+'t'} style={{backgroundColor: value.farbaTypu, color: value.farbaPismaTypu}}
                                        ref={(el) => {if (el) {
                                            el.style.setProperty('background-color', value.farbaTypu, 'important');
                                        }}}>{value.idTypu}</span> 
                                        : null }
                                </div>*/
                return <a href={value.vysledneUrl} className="fs-search-vysledky-odkaz" key={index+'a'} onClick={() => Cookiesky.zapisCookies(props.citatCookies, value.idPolozky)} style={{zIndex: '100'}}>
                    <div className="fs-search-vysledky-cely-riadok" key={index+'d'} style={{backgroundColor: index == props.vybranyTicker ? 'rgba(232,232,232,255)': ''}}>
                        
                    <li key={index + 'o'} className="fs-search-vysledky-riadok list-group-item">
                                <div className="fs-search-vysledky-sekcia-tickeru">
                                    <span className="fs-search-vysledky-nazov" key={index+'a'}>
                                        {(value.nazov.length + (value.kod != null ?(mobilnaVerzia?0:(value.kod.length+2)):0)) >= orezanieNazvu ? value.nazov.substring(0,(orezanieNazvu - (value.kod != null ?(mobilnaVerzia?0:(value.kod.length+2)):0))) + '...' : value.nazov}
                                    </span>
                                    <span className="fs-search-vysledky-ticker" key={index+'k'}>{value.kod ? '(' + value.kod + ')' : ''}</span>
                                    { value.idSkupiny != null ? 
                                        <span className="fs-search-vysledky-skupina" key={index+'s'} style={{/*backgroundColor: value.farbaSkupiny, color: value.farbaPismaSkupiny*/}}
                                        ref={(el) => {if (el) {
                                            // el.style.setProperty('background-color', value.farbaSkupiny, 'important');
                                        }}}>{value.idSkupiny}</span> 
                                        : null }
                                </div>
                                
                            </li>
                       </div>
                        </a>
                })}

                {props.bestTickery.map((value, index) => { 
                                                    /*
                                <div style={{marginLeft: 'auto'}} className="fs-search-vysledky-sekcia-marketu">
                                    { value.idSkupiny != null ? 
                                        <span className="fs-search-vysledky-skupina" key={index+'s'} style={{backgroundColor: value.farbaSkupiny, color: value.farbaPismaSkupiny}}
                                        ref={(el) => {if (el) {
                                            el.style.setProperty('background-color', value.farbaSkupiny, 'important');
                                        }}}>{value.idSkupiny}</span> 
                                        : null }
                                    { value.idTypu != null ? 
                                        <span className="fs-search-vysledky-typ" key={index+'t'} style={{backgroundColor: value.farbaTypu, color: value.farbaPismaTypu}}
                                        ref={(el) => {if (el) {
                                            el.style.setProperty('background-color', value.farbaTypu, 'important');
                                        }}}>{value.idTypu}</span> 
                                        : null }
                                </div>
                                */
                return <a href={value.vysledneUrl} className="fs-search-vysledky-odkaz" key={index+'a'} onClick={() => Cookiesky.zapisCookies(props.citatCookies, value.idPolozky)} style={{zIndex: '100'}}>
                    <div className="fs-search-vysledky-cely-riadok" key={index+'d'} style={{backgroundColor: index + props.cookiesTickery.length == props.vybranyTicker ? 'rgba(232,232,232,255)': ''}}>
                        
                            <li key={index + 'o'} className="fs-search-vysledky-riadok list-group-item">
                                <div className="fs-search-vysledky-sekcia-tickeru">
                                    <span className="fs-search-vysledky-nazov" key={index+'a'}>
                                    {(value.nazov.length + (value.kod != null ?(mobilnaVerzia?0:(value.kod.length+2)):0)) >= orezanieNazvu ? value.nazov.substring(0,(orezanieNazvu - (value.kod != null ?(mobilnaVerzia?0:(value.kod.length+2)):0))) + '...' : value.nazov}
                                    </span>
                                    <span className="fs-search-vysledky-ticker" key={index+'k'}>{value.kod ? '(' + value.kod + ')' : ''}</span>
                                    { value.idSkupiny != null ? 
                                        <span className="fs-search-vysledky-skupina" key={index+'s'} style={{/*backgroundColor: value.farbaSkupiny, color: value.farbaPismaSkupiny*/}}
                                        ref={(el) => {if (el) {
                                            // el.style.setProperty('background-color', value.farbaSkupiny, 'important');
                                        }}}>{value.idSkupiny}</span> 
                                        : null }
                                </div>
                            </li>
                       </div>
                        </a>
                })}
            </ul>
       </div>
        
    )
    
}

